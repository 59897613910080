.mini-drawer {
  .title {
    flex-grow: 1;
    display: flex;
    cursor: pointer;
  }

  .logo {
    height: 48px;
  }

  .impersonatedLabel {
    color: #f50057;
    fontSize: 10;
    fontWeight: 500;
    display: block;
  }

  .groupTitle {
    padding: 16px;
    padding-bottom: 0;
  }

  .main {
    padding: 0px;
  }

  .buttons {
    display: flex;
    margin-left: 16px;
  }
}
