.col-container {
  .col {
    //background-color: rgba(100, 100, 200, 0.3);
    height: 100%;
    //border: 1px solid white;
    display: flex;
    flex-flow: row;
    //z-index: 1000;

    //&:hover {
    //  background-color: rgba(100, 100, 200, 0.3);
    //}

    &.focused {
      background-color: rgba(50, 50, 50, 0.3);
    }

    &:first-child {
      .col-title {
        border-left: 0px;
      }
    }

    &:last-child {
      .col-title {
        border-right: 0px;
      }
    }

    .col-title {
      border: 1px solid rgba(255, 255, 255, 0.5);
      background-color: rgba(87, 95, 123, 0.8);
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      -o-user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      user-select: none;

      h6 {
        font-size: 1.6rem;
        color: lightgrey;
      }
    }
  }
}
