.edit-board-dialog {
  h2 {
    min-height: 40px;
    line-height: inherit;
  }

  .divider {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.wide-tooltip {
  max-width: unset !important;
}
