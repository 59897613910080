.loading-shading-mui {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(255, 255, 255, .3);

  .message {
    position: absolute;
    top: calc(50% + 30px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.loading-icon-mui {
  position: absolute;
  font-size: 20px;
  top: calc(45% - 10px);
  left: calc(50% - 10px);
}

.small-loading-icon-mui {
  padding-left: 0.2em;
  padding-right: 0.2em;
}
