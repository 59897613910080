.edit-card-dialog {
  min-width: 400px;

  h2 {
    min-height: 40px;
    line-height: inherit;
  }

  .color-buttons {
    text-align: end;
    margin-top: 12px;

    .color-btn {
      width: 40px;
      height: 40px;
    }
  }
}
