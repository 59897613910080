.siteRoot{
    display: flex !important;

    // Setting min-height: 100vh; breaks dialogs
    min-height: 100vh;
    min-width: 1240px;

    flex-direction: column;

    margin-left: auto;
    margin-right: auto;

    .sap-closure {
        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid lightgray;
        height: 56px;

        h3 {
            color: #dc004e;
            line-height: 35px;
        }
    }
}

.siteContent{
    flex: 1;
    max-width: 1240px;
    min-width: 1240px;
    margin: auto;
}

.page-header {
    display: flex;
    margin-top: 20px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgray;
    justify-content: space-between;
    width: 100%;    // in case it is inside flex container
    height: 56px;

    h2 {
        display: flex;
        align-items: center;
    }
}
