.board-select-dialog {
  h2 {
    min-height: 40px;
    line-height: inherit;
  }

  .open-board-btn {
    margin-left: -20px;
  }

  .actions {
    justify-content: space-between;
  }

  .edit-board-btn {
    float: right;
  }

  .board-item {
    padding-right: 0;
  }
}
