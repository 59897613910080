.card-container {
  background-color: #009688;
  width: 200px;
  height: 150px;
  //border: 2px solid cadetblue;
  box-shadow: rgba(0,0,0,0.6) 0px 1px 3px, rgba(0,0,0,0.5) 0px 1px 2px;
  //cursor: grab;
  position: absolute;
  min-width: 50px;
  min-height: 50px;

  &.filtered-out {
    opacity: 0.4;
  }

  overflow: hidden;
  display: grid;
  //grid-auto-rows: min-content;
  grid-template-columns: auto;
  //grid-template-rows: auto auto;
  grid-template:
    "title title"
    "body body" 1fr
    "tags tags"
    "status actions";
  grid-template-columns: 28px auto;

  .edit {
    border: none;
    outline: none;
    background-color: transparent;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.00938em;
    resize: none;
  }

  .para {
    //cursor: grab;
    margin: 0px 4px;
    overflow: hidden;

    .link {
      -webkit-user-drag: none;
      user-select: text;
      cursor: pointer;
    }

    .view {
      overflow: hidden;
      cursor: auto
    }

    &.actions {
      text-align: end;
      margin-right: 10px;
      height: 24px;
      button {
        opacity: 0.6;
      }
    }

    &.status {
      svg {
        width: 16px;
        filter: brightness(0.75);
      }
      margin-top: 2px;
    }

    &.tags {
      .tag {
        margin-right: 4px;
        color: #424242;
        font-weight: bold;
        cursor: pointer;
        opacity: 0.8;
        background-color: #bdbdbd;
        border-radius: 16px;
        padding-left: 2px;
        padding-right: 2px;
        &:hover {
          background-color: #eeeeee;
        }
        &.clicked {
          background-color: #eeeeee;
        }
      }
    }

    .hgl {
      animation: blinker 1s linear infinite;
    }
  }
}

@keyframes blinker {
  50% {
    filter: invert(1);
  }
}
