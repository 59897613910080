.columns-config-dialog {
  min-width: 600px;

  h2 {
    min-height: 40px;
    line-height: inherit;
  }

  .allign-number {
    text-align: right;

    input {
      text-align: right;
    }
  }

  .totals {
    margin-top: 8px;
    h6 {
      font-weight: bold;
    }
  }

  .width-col {
    width: 100px;
  }

  .mapping-col {
    width: 150px;
  }

  .action-col {
    width: 50px;
    align-self: self-end;
  }

  .drag-col {
    width: 50px;
    //align-self: center;
    cursor: move;
  }

  .sync-to-do {
    width: 320px;
    align-self: self-end;
    text-align: end;
  }

  .map-type-col {
    width: 220px;
  }

  .title {
    .title-col {
      padding-top: 30px;
    }

    .width-col {
      padding-top: 30px;
    }

    .mapping-col {
      padding-top: 30px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .row-container {
    border-left: 8px solid forestgreen;
    margin-bottom: 8px;
    padding-left: 4px;
    padding-right: 4px;
    //margin-top: 8px;
  }
}
