.col-container {
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
}

.board-container {
  cursor: grab;

  &.scrolling {
    cursor: grabbing;
  }
}

.react-draggable {
  cursor: grab;
}

.react-draggable-dragging {
  cursor: grabbing;
}
